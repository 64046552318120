import { SupportedNetworks } from '@solidant/spool-v2-fe-lib'

// we use this as the tokenId for native currency as there's no tokenId
export const NATIVE_TOKEN = 'native'

// ARB
export const tokenAddresses: Record<
  SupportedNetworks,
  { [key: string]: string }
> = {
  [SupportedNetworks.ETHEREUM]: {
    ETH: 'native',
    WETH: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    USDC: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    USDT: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    DAI: '0x6b175474e89094c44da98b954eedeac495271d0f',
    SPOOL: '0x40803cea2b2a32bda1be61d3604af6a814e70976',
    YLAY: '0xaee5913ffd19dbca4fd1ef6f3925ed0414407d37',
  },
  [SupportedNetworks.ARBITRUM]: {
    ETH: 'native',
    WETH: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    USDC: '0xaf88d065e77c8cc2239327c5edb3a432268e5831',
    USDT: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
    DAI: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
    SPOOL: '0xeca14f81085e5b8d1c9d32dcb596681574723561',
    // TODO: change to ARB contract once we have it
    YLAY: '0xaee5913ffd19dbca4fd1ef6f3925ed0414407d37',
  },
  [SupportedNetworks.SEPOLIA]: {
    ETH: 'native',
    WETH: '0x88b25a14dae46c33ae3eef2b45a5ec758d9a5ec6',
    USDC: '0xa6b92fcd4ee124353c8a6acf1edb574f46f3f8df',
    USDT: '0x72d6a42377e7511ff69aab65fb3c075512fcbae5',
    DAI: '0x2a3a3872c242c35093a8fc48dac838c4b2d24a03',
    SPOOL: '0xeca14f81085e5b8d1c9d32dcb596681574723561',
    YLAY: '0xaee5913ffd19dbca4fd1ef6f3925ed0414407d37',
  },
}

// ARB
export const tokenSymbols: { [key: string]: string } = {
  ['0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2']: '$WETH',
  ['0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48']: '$USDC',
  ['0xdac17f958d2ee523a2206206994597c13d831ec7']: '$USDT',
  ['0x6b175474e89094c44da98b954eedeac495271d0f']: '$DAI',
  ['0x40803cea2b2a32bda1be61d3604af6a814e70976']: '$SPOOL',
  ['0xae78736Cd615f374D3085123A210448E74Fc6393']: '$RETH',
  ['0x5e74C9036fb86BD7eCdcb084a0673EFc32eA31cb']: '$SETH',
  ['0x5E8422345238F34275888049021821E8E08CAa1f']: '$FRXETH',
}

// ARB
export const WRAPPED_TOKEN_ADDRESS: { [key: string]: string } = {
  [SupportedNetworks.ETHEREUM]: tokenAddresses[SupportedNetworks.ETHEREUM].WETH,
  [SupportedNetworks.ARBITRUM]: tokenAddresses[SupportedNetworks.ARBITRUM].WETH,
}
